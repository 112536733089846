// @flow
import { useStateValue } from '../store/state';
import crmService from '../services/crmClientService';
import type { DonationRequestCreate } from '../services/flow-typed/crmClientServiceTypes';

/**
 * Route donation request to guest/customer CRM endpoint call.
 *
 * Handle the state checks that determine when guest / customer CRM method
 * should be called and returns callable function routed to appropriate CRM
 * donation request method.
 *
 * @returns {function} - Callable function to subscribe to newsletter.
 */
function donationRequestRouter(state) {
  const { CRMCustomerID, access_token } = state.user;

  return (donationRequest: DonationRequestCreate) => {
    if (CRMCustomerID && access_token) {
      return crmService.donationRequest(CRMCustomerID, donationRequest, access_token);
    } else {
      return crmService.donationRequest(donationRequest.EmailAddress, donationRequest);
    }
  };
}

export default donationRequestRouter
