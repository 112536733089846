import * as yup from 'yup';
import locale from '../../locale/locale';
import {useStoresStaticQuery} from "../../hooks/useStoresStaticQuery";
import {compact, map} from "lodash";

export const DonationSchema = isStore => {
  const allStores = useStoresStaticQuery();
  const storeList = map(compact(
    map(allStores, (node) => (node.crmId ? { id: node.crmId, label: node.title } : false))
  ), 'label');
  const StoreNumber = isStore ?
    yup.mixed().oneOf(storeList) :
    yup.string()

  return yup.object().shape({
    Name: yup.string().required(),
    supportArea: yup.mixed().oneOf(["DiseasePrevention", "ChildrenAndYouthDevelopment", "FoodAidPrograms", "LocalCommunity"]),
    EmailAddress: yup
      .string()
      .email()
      .required(),
    Phone: yup
      .string()
      .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
        message: locale.donationRequest.Phone.error,
      })
      .required(),
    OrganizationName: yup
      .string()
      .required(locale.donationRequest.OrganizationName.error),
    PrimaryAddress_Street1: yup
      .string()
      .required(locale.donationRequest.PrimaryAddress_Street1.error),
    PrimaryAddress_City: yup
      .string()
      .required(locale.donationRequest.PrimaryAddress_City.error),
    PrimaryAddress_PostalCode: yup
      .string()
      .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, {
        message: locale.donationRequest.PrimaryAddress_PostalCode.validationError,
      })
      .required(locale.donationRequest.PrimaryAddress_PostalCode.error),
    PrimaryAddress_Province: yup
      .string()
      .length(2, locale.donationRequest.PrimaryAddress_Province.errorDefault)
      .notOneOf([locale.form.province], locale.donationRequest.PrimaryAddress_Province.errorDefault)
      .required(locale.donationRequest.PrimaryAddress_Province.error),
    PrimaryPhone: yup
      .string()
      .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
        message: locale.donationRequest.PrimaryPhone.validationError,
      })
      .required(locale.donationRequest.PrimaryPhone.error),
    OtherSupport: yup
        .string()
        .max(100, locale.donationRequest.maxCharLimitErrorMsg),
    DescriptionOfGoalsAndPurpose: yup
      .string()
      .max(500, locale.donationRequest.maxCharLimitErrorMsg)
      .required(locale.donationRequest.DescriptionOfGoalsAndPurpose.error),
    EventDetails: yup
      .string()
      .max(500, locale.donationRequest.maxCharLimitErrorMsg)
      .required(locale.donationRequest.EventDetails.error),
    RequestedSupportDetails: yup
      .string()
      .max(200, locale.donationRequest.maxCharLimitErrorMsg)
      .required(locale.donationRequest.RequestedSupportDetails.error),
    HowLongosSupportRecognized: yup
        .string()
        .max(100, locale.donationRequest.maxCharLimitErrorMsg),
    RequiredLongosLogoSizeAndFormat: yup
        .string()
        .max(100, locale.donationRequest.maxCharLimitErrorMsg),
    EventName: yup.string().required(locale.donationRequest.EventName.error),
    EventAddress: yup.string().required(locale.donationRequest.EventAddress.error),
    EventDate: yup.string().required(locale.donationRequest.EventDate.error),
    LongosLogoRequiredFalse: yup.string(),
    LongosLogoRequired: yup.string(),
    LongosSupportRecognized: yup.string(),
    StoreNumber,
  });
};
