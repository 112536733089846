// Libs
import React, { useEffect, useRef, useState, useMemo } from 'react';
import useForm from 'react-hook-form';
import * as PropTypes from 'prop-types';
import { map, compact, isEmpty, sortBy, has } from 'lodash';
import DatePicker from 'react-date-picker';
// State
import { DonationSchema } from './donation-schema';
import donationRequestRouter from '../../hooks/donationRequestRouter';
import { useStateValue } from '../../store/state';
import { crmFriendlyDate } from '../../libs/form/dates';
// Assets
import styles from './donation-form.module.scss';
import locale from '../../locale/locale';
import formLocale from '../../data/community-forms';
import pageAliases from '../../data/pageAliases';
import { addNotification } from '../../libs/notify';
import { useStoresStaticQuery } from '../../hooks/useStoresStaticQuery';
import FormInputDonation from '../common/form-input-donation';
import FormLoader from '../common/loaders/form-loader';
import ButtonLoader from '../common/loaders/button-loader';
import SetInnerHtml from '../common/set-inner-html/set-inner-html';
import navigate from '../../libs/navigate';
import { pushEvent } from '../../libs/dataLayer';

const DonationForm = ({ isStore }) => {
  const [state] = useStateValue();
  const { customer } = state;
  const errorComponents = useRef(null);
  const [globalError, setGlobalError] = useState('');
  const [lastSubmit, setLastSubmit] = useState(0);
  const [submission, setSubmission] = useState(false);
  const validationSchema = DonationSchema(isStore);
  const { register, handleSubmit, errors, setValue, formState, setError } = useForm({
    defaultValues: {
      Name: customer.FirstName ? `${customer.FirstName} ${customer.LastName}` : '',
      Phone: customer.PrimaryPhone || '',
      EmailAddress: customer.EmailAddress || '',
      PrimaryAddress_Province: 'ON',
    },
    validationSchema: validationSchema,
    submitFocusError: true,
    nativeValidation: false,
  });
  const allStores = useStoresStaticQuery();

  const storeList = sortBy(
    compact(map(allStores, (node) => (node.crmId ? { id: node.crmId, label: node.title } : false))),
    'label',
  );

  const today = new Date();
  useEffect(() => {
    setValue('EventDate', `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);
  }, []);

  const [transDate, setTransDate] = useState({ value: new Date(), error: false, string: '' });
  const onDateSelected = (date) => {
    if (date) {
      setValue('EventDate', `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, true);
      setTransDate({ value: date, error: false });
    } else {
      setValue('EventDate', '', true);
    }
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      setGlobalError(locale.donationRequest.errorProcessingRequest);
    } else {
      setGlobalError('');
    }
  }, [errors]);

  const focusOnError = () => {
    const firstErrorEl =
      document.querySelector('[data-error="true"]') ||
      (errorComponents.current && errorComponents.current.innerHTML !== ''
        ? errorComponents.current
        : null);

    if (firstErrorEl) {
      setSubmission(false);
      firstErrorEl.focus();
    }
  };

  useEffect(() => {
    // An issue with the first submit not handling the isSubmitting flag, catch it here.
    if (formState.submitCount !== lastSubmit) {
      setLastSubmit(formState.submitCount);
      // Delay to let error state to properly update.
      setTimeout(focusOnError, 100);
    }
  }, [formState.submitCount, errors]);

  const onSubmit = (data) => {
    if (submission) {
      return;
    }
    setSubmission(true);
    const fields = { ...data };
    let additionalValidation = false;
    // Retrieve CRM store id, or use fallback.
    try {
      fields['StoreNumber'] = isStore
        ? storeList.filter((item) => item.label === fields['StoreNumber'])[0].id
        : 10;
    } catch (e) {
      fields['StoreNumber'] = 10;
    }
    // Select selected ration option as bool, defined by the API.
    fields['DiseasePrevention'] = false;
    fields['ChildrenAndYouthDevelopment'] = false;
    fields['FoodAidPrograms'] = false;
    fields['LocalCommunity'] = false;

    // Set support area value.
    fields[data['supportArea'] || 'DiseasePrevention'] = true;

    // For prov to uppercase.
    fields['PrimaryAddress_Province'] = data['PrimaryAddress_Province'].toUpperCase();

    fields['HasRequestedSupportInLast12Months'] =
      data['HasRequestedSupportInLast12Months'] === 'true';
    fields['LongosSupportRecognized'] = data['LongosSupportRecognized'] === 'true';
    fields['LongosLogoRequired'] = data['LongosLogoRequired'] === 'true';

    fields['OtherSupport'] = fields['OtherSupport'] ? fields['OtherSupport'] : '';
    if (!fields['LongosLogoRequired']) {
      fields['RequiredLongosLogoSizeAndFormat'] = fields['RequiredLongosLogoSizeAndFormat']
        ? fields['RequiredLongosLogoSizeAndFormat']
        : ' ';
    } else {
      if (!fields['RequiredLongosLogoSizeAndFormat']) {
        setError('RequiredLongosLogoSizeAndFormat', 'required', 'Field is required');
        additionalValidation = true;
      }
    }

    if (!fields['LongosSupportRecognized']) {
      fields['HowLongosSupportRecognized'] = fields['HowLongosSupportRecognized']
        ? fields['HowLongosSupportRecognized']
        : ' ';
    } else {
      if (!fields['HowLongosSupportRecognized']) {
        setError('HowLongosSupportRecognized', 'required', 'Field is required');
        additionalValidation = true;
      }
    }

    if (additionalValidation) {
      setSubmission(false);
      return;
    }

    fields['RequiredLongosLogoSizeAndFormat'] = fields['RequiredLongosLogoSizeAndFormat']
      ? fields['RequiredLongosLogoSizeAndFormat']
      : ' ';

    fields['EventDate'] = crmFriendlyDate(transDate);

    fields['IsThisSponsorship'] = !isStore;

    // console.log('submitting form', fields);

    const donationPromise = donationRequestRouter(state);
    donationPromise(fields)
      .then((data) => {
        // Handle errors.
        if (!data) {
          setSubmission(false);
          return setGlobalError(locale.donationRequest.invalidFormFields);
        }
        pushEvent('interaction', 'Store giving request submit', fields);

        // Success, let the user know on return to the community page with notice.
        setSubmission(false);
        navigate(pageAliases.inTheCommunity);
        addNotification(locale.donationRequest.submitSuccess);
      })
      .catch((error) => {
        pushEvent('interaction', 'Store giving request submit failed.', fields);
        setSubmission(false);
        setGlobalError(locale.donationRequest.errorProcessingRequest);
      });
  };

  const isCrmUser = has(customer, 'CRMCustomerID') && customer.CRMCustomerID !== '';
  return useMemo(
    () => (
      <div className={styles.donationFormWrapper}>
        <div className={styles.donationForm}>
          <h1 className={styles.donationFormTitle}>
            {isStore
              ? formLocale.storeRequest.storeRequestTitle
              : formLocale.donationRequest.donationTitle}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            {submission && <FormLoader/>}
            <SetInnerHtml
              className={styles.donationFormDescription}
              data={{
                content: isStore
                  ? formLocale.storeRequest.storeRequestFormDescription
                  : formLocale.donationRequest.donationFormDescription,
              }}
            />
            <div className={styles.donationFormDetails}>
              {isStore
                ? formLocale.storeRequest.storeRequestFormDetails
                : formLocale.donationRequest.donationFormDetails}
            </div>
            <div tabIndex={0} ref={errorComponents} className={styles.donationFormGlobalError}>
              {globalError ? globalError : ''}
            </div>

            <div className={styles.donationFormAboutPerson}>
              <label>{locale.donationRequest.aboutPerson}</label>
              <input
                type="text"
                name="Name"
                id="Name"
                placeholder={locale.donationRequest.Name.placeholder}
                aria-label={locale.donationRequest.Name.placeholder}
                data-error={!!errors['Name']}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['Name'] && <span>{errors['Name'].message}</span>}
              </div>
              <input
                type="text"
                name="EmailAddress"
                aria-label={locale.donationRequest.EmailAddress.placeholder}
                placeholder={locale.donationRequest.EmailAddress.placeholder}
                data-error={!!errors['EmailAddress']}
                ref={register({ required: !isCrmUser })}
                disabled={isCrmUser && 'disabled'}
              />
              <input
                type="text"
                name="Phone"
                id="Phone"
                placeholder={locale.donationRequest.Phone.placeholder}
                aria-label={locale.donationRequest.Phone.placeholder}
                data-error={!!errors['Phone']}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['EmailAddress'] && <span> {errors['EmailAddress'].message} </span>}
              </div>
              <div className={styles.formInputError}>
                {errors['Phone'] && <span> {errors['Phone'].message} </span>}
              </div>
            </div>
            <div className={styles.donationFormAboutOrganisation}>
              <label>{locale.donationRequest.OrganizationLabel}</label>
              <input
                type="text"
                name="OrganizationName"
                placeholder={locale.donationRequest.OrganizationName.placeholder}
                aria-label={locale.donationRequest.OrganizationName.placeholder}
                data-error={!!errors['OrganizationName']}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['OrganizationName'] && <span> {errors['OrganizationName'].message} </span>}
              </div>
              <input
                type="text"
                name="PrimaryAddress_Street1"
                placeholder={locale.donationRequest.PrimaryAddress_Street1.placeholder}
                aria-label={locale.donationRequest.PrimaryAddress_Street1.placeholder}
                data-error={!!errors['PrimaryAddress_Street1']}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <input
                type="text"
                name="PrimaryAddress_City"
                placeholder={locale.donationRequest.PrimaryAddress_City.placeholder}
                aria-label={locale.donationRequest.PrimaryAddress_City.placeholder}
                data-error={!!errors['PrimaryAddress_City']}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['PrimaryAddress_Street1'] && (
                  <span> {errors['PrimaryAddress_Street1'].message} </span>
                )}
              </div>
              <div className={styles.formInputError}>
                {errors['PrimaryAddress_City'] && (
                  <span> {errors['PrimaryAddress_City'].message} </span>
                )}
              </div>
              <input
                type="text"
                name="PrimaryAddress_PostalCode"
                placeholder={locale.donationRequest.PrimaryAddress_PostalCode.placeholder}
                aria-label={locale.donationRequest.PrimaryAddress_PostalCode.placeholder}
                data-error={!!errors['PrimaryAddress_PostalCode']}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <FormInputDonation
                className={styles.formSelectProvince}
                placeholder={locale.form.province}
                type="select"
                name="PrimaryAddress_Province"
                options={[
                  { value: locale.form.province, label: locale.form.province, disabled: true },
                  { value: 'AB', label: 'Alberta' },
                  { value: 'BC', label: 'British Columbia' },
                  { value: 'MB', label: 'Manitoba' },
                  { value: 'NB', label: 'New Brunswick' },
                  { value: 'NL', label: 'Newfoundland and Labrador' },
                  { value: 'NT', label: 'Northwest Territories' },
                  { value: 'NS', label: 'Nova Scotia' },
                  { value: 'NU', label: 'Nunavut' },
                  { value: 'ON', label: 'Ontario' },
                  { value: 'PE', label: 'Prince Edward Island' },
                  { value: 'QC', label: 'Quebec' },
                  { value: 'SK', label: 'Saskatchewan' },
                  { value: 'YT', label: 'Yukon' },
                ]}
                data-error={!!errors['PrimaryAddress_Province']}
                formRef={register({ required: true })}
              />
              <input
                type="text"
                name="PrimaryPhone"
                aria-label={locale.donationRequest.PrimaryPhone.placeholder}
                placeholder={locale.donationRequest.PrimaryPhone.placeholder}
                data-error={!!errors['PrimaryPhone']}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['PrimaryAddress_PostalCode'] && (
                  <span> {errors['PrimaryAddress_PostalCode'].message} </span>
                )}
              </div>
              <div className={styles.formInputError}>
                {errors['PrimaryAddress_Province'] && (
                  <span> {errors['PrimaryAddress_Province'].message} </span>
                )}
              </div>
              <div className={styles.formInputError}>
                {errors['PrimaryPhone'] && <span> {errors['PrimaryPhone'].message} </span>}
              </div>
            </div>
            <div className={styles.donationFormSupport}>
              <div
                className={styles.donationFormSupportLabel}
                dangerouslySetInnerHTML={{
                  __html: locale.donationRequest.labelSupport,
                }}
              />
              <div className={styles.donationFormSupportRadioContainer}>
                <div className={styles.donationFormSupportFieldRadio}>
                  <input
                    type="radio"
                    id="DiseasePrevention"
                    name="supportArea"
                    value="DiseasePrevention"
                    ref={register}
                    defaultChecked
                    disabled={submission ? 'disabled' : null}
                  />
                  <label htmlFor="DiseasePrevention">
                    {locale.donationRequest.DiseasePrevention}
                  </label>
                </div>
                <div className={styles.donationFormSupportFieldRadio}>
                  <input
                    type="radio"
                    id="ChildrenAndYouthDevelopment"
                    name="supportArea"
                    value="ChildrenAndYouthDevelopment"
                    ref={register}
                    disabled={submission ? 'disabled' : null}
                  />
                  <label htmlFor="ChildrenAndYouthDevelopment">
                    {locale.donationRequest.ChildrenAndYouthDevelopment}
                  </label>
                </div>
                <div className={styles.donationFormSupportFieldRadio}>
                  <input
                    type="radio"
                    id="FoodAidPrograms"
                    name="supportArea"
                    value="FoodAidPrograms"
                    ref={register}
                    disabled={submission ? 'disabled' : null}
                  />
                  <label htmlFor="FoodAidPrograms">{locale.donationRequest.FoodAidPrograms}</label>
                </div>
                <div className={styles.donationFormSupportFieldRadio}>
                  <input
                    type="radio"
                    id="LocalCommunity"
                    name="supportArea"
                    value="LocalCommunity"
                    ref={register}
                    disabled={submission ? 'disabled' : null}
                  />
                  <label htmlFor="LocalCommunity">{locale.donationRequest.LocalCommunity}</label>
                </div>
              </div>
            </div>
            <div className={styles.donationFormOtherSupport}>
              <label
                dangerouslySetInnerHTML={{
                  __html: locale.donationRequest.OtherSupportLabel,
                }}
              />
              <input
                type="text"
                name="OtherSupport"
                aria-label={locale.donationRequest.OtherSupport.placeholder}
                placeholder={locale.donationRequest.OtherSupport.placeholder}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['OtherSupport'] && (
                  <span> {errors['OtherSupport'].message} </span>
                )}
              </div>
            </div>
            <div className={styles.donationFormDescriptionPurpose}>
              <label
                dangerouslySetInnerHTML={{
                  __html: locale.donationRequest.DescriptionOfGoalsAndPurpose.label,
                }}
              />
              <textarea
                aria-label="Description"
                name="DescriptionOfGoalsAndPurpose"
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['DescriptionOfGoalsAndPurpose'] && (
                  <span> {errors['DescriptionOfGoalsAndPurpose'].message} </span>
                )}
              </div>
            </div>
            <div className={styles.donationFormRequestedSupport}>
              <div
                dangerouslySetInnerHTML={{
                  __html: locale.donationRequest.HasRequestedSupportInLast12Months.label,
                }}
              />
              <input
                type="radio"
                id="HasRequestedSupportInLast12MonthsTrue"
                name="HasRequestedSupportInLast12Months"
                value="true"
                ref={register}
                defaultChecked
                disabled={submission ? 'disabled' : null}
              />
              <label htmlFor="HasRequestedSupportInLast12MonthsTrue">
                {locale.donationRequest.HasRequestedSupportInLast12Months.options['0']}
              </label>
              <input
                type="radio"
                id="HasRequestedSupportInLast12MonthsFalse"
                name="HasRequestedSupportInLast12Months"
                ref={register}
                value="false"
                disabled={submission ? 'disabled' : null}
              />
              <label htmlFor="HasRequestedSupportInLast12MonthsFalse">
                {locale.donationRequest.HasRequestedSupportInLast12Months.options['1']}
              </label>
            </div>
            <div className={styles.donationFormEvent}>
              <div className={styles.donationFormEventLabel}>
                {locale.donationRequest.EventLabel}
              </div>
              <input
                type="text"
                name="EventName"
                aria-label={locale.donationRequest.EventName.placeholder}
                placeholder={locale.donationRequest.EventName.placeholder}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['EventName'] && <span> {errors['EventName'].message} </span>}
              </div>
              <input
                type="text"
                name="EventAddress"
                aria-label={locale.donationRequest.EventAddress.placeholder}
                placeholder={locale.donationRequest.EventAddress.placeholder}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <input type="text" name="EventDate" ref={register}/>
              <div className={styles.orderAddMissingPointsCalendarContainer}>
                <DatePicker
                  format={'MM/dd/yyyy'}
                  onChange={onDateSelected}
                  calendarType="US"
                  calendarClassName={[styles.calendarBody]}
                  className={[
                    styles.calendarBase,
                    transDate.value ? styles.calendarBaseDateSelected : '',
                  ]}
                  clearIcon={null}
                  value={transDate.value}
                  minDate={today}
                  monthAriaLabel={locale.calendar.monthAriaLabel}
                  dayAriaLabel={locale.calendar.dayAriaLabel}
                  yearAriaLabel={locale.calendar.yearAriaLabel}
                  nativeInputAriaLabel={locale.calendar.nativeInputAriaLabel}
                  calendarAriaLabel={locale.calendar.calendarAriaLabel}
                />
              </div>
              <div className={styles.formInputError}>
                {errors['EventAddress'] && <span> {errors['EventAddress'].message} </span>}
              </div>
              <div className={styles.formInputError}>
                {errors['EventDate'] && <span> {errors['EventDate'].message} </span>}
              </div>
              <div className={styles.donationFormDescriptionPurpose}>
                <label
                  dangerouslySetInnerHTML={{
                    __html: locale.donationRequest.EventDetails.label,
                  }}
                />
                <textarea
                  aria-label="Description"
                  name="EventDetails"
                  ref={register({ required: true })}
                  disabled={submission ? 'disabled' : null}
                />
                <div className={styles.formInputError}>
                  {errors['EventDetails'] && <span> {errors['EventDetails'].message} </span>}
                </div>
              </div>
              <div className={styles.donationFormDescriptionPurpose}>
                <label
                  dangerouslySetInnerHTML={{
                    __html: locale.donationRequest.RequestedSupportDetails.label,
                  }}
                />
                <textarea
                  aria-label="Description"
                  name="RequestedSupportDetails"
                  ref={register({ required: true })}
                  disabled={submission ? 'disabled' : null}
                />
                <div className={styles.formInputError}>
                  {errors['RequestedSupportDetails'] && (
                    <span> {errors['RequestedSupportDetails'].message} </span>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.donationFormRequestedSupport}>
              <div
                dangerouslySetInnerHTML={{
                  __html: locale.donationRequest.LongosSupportRecognized.label,
                }}
              />
              <input
                type="radio"
                id="LongosSupportRecognizedTrue"
                name="LongosSupportRecognized"
                value="true"
                ref={register}
                defaultChecked
                disabled={submission ? 'disabled' : null}
              />
              <label htmlFor="LongosSupportRecognizedTrue">
                {locale.donationRequest.LongosSupportRecognized.options['0']}
              </label>
              <input
                type="radio"
                id="LongosSupportRecognizedFalse"
                name="LongosSupportRecognized"
                ref={register}
                value="false"
                disabled={submission ? 'disabled' : null}
              />
              <label htmlFor="LongosSupportRecognizedFalse">
                {locale.donationRequest.LongosSupportRecognized.options['1']}
              </label>
            </div>

            <div className={styles.donationFormOtherSupport}>
              <label
                dangerouslySetInnerHTML={{
                  __html: locale.donationRequest.HowLongosSupportRecognized.label,
                }}
              />
              <input
                type="text"
                name="HowLongosSupportRecognized"
                aria-label={locale.donationRequest.HowLongosSupportRecognized.placeholder}
                placeholder={locale.donationRequest.HowLongosSupportRecognized.placeholder}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['HowLongosSupportRecognized'] && (
                  <span> {errors['HowLongosSupportRecognized'].message} </span>
                )}
              </div>
            </div>
            <div className={styles.donationFormRequestedSupport}>
              <div
                dangerouslySetInnerHTML={{
                  __html: locale.donationRequest.LongosLogoRequired.label,
                }}
              />
              <input
                type="radio"
                id="LongosLogoRequiredTrue"
                name="LongosLogoRequired"
                value="true"
                ref={register}
                defaultChecked
                disabled={submission ? 'disabled' : null}
              />
              <label htmlFor="LongosLogoRequiredTrue">
                {locale.donationRequest.LongosLogoRequired.options['0']}
              </label>
              <input
                type="radio"
                id="LongosLogoRequiredFalse"
                name="LongosLogoRequired"
                ref={register}
                value="false"
                disabled={submission ? 'disabled' : null}
              />
              <label htmlFor="LongosLogoRequiredFalse">
                {locale.donationRequest.LongosLogoRequired.options['1']}
              </label>
            </div>
            <div className={styles.donationFormOtherSupport}>
              <label
                dangerouslySetInnerHTML={{
                  __html: locale.donationRequest.RequiredLongosLogoSizeAndFormat.label,
                }}
              />
              <input
                aria-label={locale.donationRequest.RequiredLongosLogoSizeAndFormat.placeholder}
                type="text"
                name="RequiredLongosLogoSizeAndFormat"
                placeholder={locale.donationRequest.RequiredLongosLogoSizeAndFormat.placeholder}
                ref={register({ required: true })}
                disabled={submission ? 'disabled' : null}
              />
              <div className={styles.formInputError}>
                {errors['RequiredLongosLogoSizeAndFormat'] && (
                  <span> {errors['RequiredLongosLogoSizeAndFormat'].message} </span>
                )}
              </div>
            </div>
            {isStore && (
              <div className={styles.donationFormAutocomplete}>
                <label
                  dangerouslySetInnerHTML={{
                    __html: locale.donationRequest.donationFormAutocomplete.label,
                  }}
                />
                <input
                  name="StoreNumber"
                  type="text"
                  autoComplete="off"
                  list="stores"
                  ref={register}
                  placeholder={locale.donationRequest.storeNumber.placeholder}
                  aria-label={locale.donationRequest.storeNumber.placeholder}
                  disabled={submission ? 'disabled' : null}
                />
                <datalist id="stores">
                  {storeList.map((store, key) => {
                    return <option key={key}>{store.label}</option>;
                  })}
                </datalist>
                <div className={styles.formInputError}>
                  {errors['StoreNumber'] && (
                    <span> {locale.donationRequest.storeNumber.error} </span>
                  )}
                </div>
              </div>
            )}
            <button
              type={`submit`}
              className={styles.donationFormButton}
              aria-label={locale.donationRequest.submit}
            >
              {locale.donationRequest.submit}
              {submission && <ButtonLoader/>}
            </button>
          </form>
        </div>
      </div>
    ),
    [errors, transDate, globalError, submission],
  );
};

DonationForm.defaultProps = {
  isStore: false,
};

DonationForm.propTypes = {
  isStore: PropTypes.bool,
};

export default DonationForm;
