// Libs
import React from 'react';
// Deps
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import DonationForm from '../components/donation/donation-form';
import styles from '../components/donation/donation-form.module.scss';

const CorporateGivingRequest = () => {
  return (
    <Layout>
      <div className={styles.donationFormContainerColored}>
        <SEO title="Sponsorship and Corporate Giving Request" />
        <DonationForm isStore={false} />
      </div>
    </Layout>
  );
};

export default CorporateGivingRequest;
